const routers=[
    {
        path: '/news',
        name: 'news',
        component: () => import(/* webpackPrefetch: true */ '@/views/news/news.vue'),
        meta:{
            title:'新闻',
            content:{
                keywords:'燃烧能源中心,能源与动力工程系,清华大学,异相反应流与能源转化课题组',
                description:'异相反应流与能源转化课题组'
            },
            keepAlive:true 
        },
    },
    {
        path: '/people',
        name: 'people',
        component: () => import(/* webpackPrefetch: true */ '@/views/tsinghua/people.vue'),
        meta:{
            title:'人员',
            content:{
                keywords:'燃烧能源中心,能源与动力工程系,清华大学,异相反应流与能源转化课题组',
                description:'异相反应流与能源转化课题组'
            },
         keepAlive:true 
        },
    },
    {
        path: '/research',
        name: 'research',
        component: () => import(/* webpackPrefetch: true */ '@/views/tsinghua/research.vue'),
        meta:{
            title:'研究',
            content:{
                keywords:'燃烧能源中心,能源与动力工程系,清华大学,异相反应流与能源转化课题组',
                description:'异相反应流与能源转化课题组'
            },
            keepAlive:true 
        },
    },
    {
        path: '/publications',
        name: 'publications',
        component: () => import(/* webpackPrefetch: true */ '@/views/tsinghua/publications.vue'),
        meta:{
            title:'成果',
            content:{
                keywords:'燃烧能源中心,能源与动力工程系,清华大学,异相反应流与能源转化课题组',
                description:'异相反应流与能源转化课题组'
            },
            keepAlive:true 
        },
    },
    {
        path: '/laboratory',
        name: 'laboratory',
        component: () => import(/* webpackPrefetch: true */ '@/views/tsinghua/laboratory.vue'),
        meta:{
            title:'实验室',
            content:{
                keywords:'燃烧能源中心,能源与动力工程系,清华大学,异相反应流与能源转化课题组',
                description:'异相反应流与能源转化课题组'
            },
            keepAlive:true 
        },
    },
    {
        path: '/teaching',
        name: 'teaching',
        component: () => import(/* webpackPrefetch: true */ '@/views/tsinghua/teaching.vue'),
        meta:{
            title:'教学',
            content:{
                keywords:'燃烧能源中心,能源与动力工程系,清华大学,异相反应流与能源转化课题组',
                description:'异相反应流与能源转化课题组'
            },
            keepAlive:true 
        },
    },
    {
        path: '/recruitment',
        name: 'recruitment',
        component: () => import(/* webpackPrefetch: true */ '@/views/tsinghua/recruitment.vue'),
        meta:{
            title:'招生招聘',
            content:{
                keywords:'燃烧能源中心,能源与动力工程系,清华大学,异相反应流与能源转化课题组',
                description:'异相反应流与能源转化课题组'
            },
            keepAlive:true 
        },
    },
    {
        path: '/article',
        name: 'article',
        component: () => import(/* webpackPrefetch: true */ '@/views/news/article.vue'),
        meta:{
            title:'新闻详情',
            content:{
                keywords:'燃烧能源中心,能源与动力工程系,清华大学,异相反应流与能源转化课题组',
                description:'异相反应流与能源转化课题组'
            },
            keepAlive:true 
        },
    },
]

export default routers