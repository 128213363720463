import { createRouter,createWebHashHistory} from 'vue-router'
// import Home from '../views/homePage/homePage.vue'
import routers from './activeRouter/index.js'
import { ref } from 'vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component:()=>import(/* webpackPreload: true */'@/views/homePage/homePage.vue'),
    meta:{
      title:'异相反应流与能源转化课题组',
      content:{
        keywords:'燃烧能源中心,能源与动力工程系,清华大学,异相反应流与能源转化课题组',
        description:'异相反应流与能源转化课题组'
      }
    },
  },
  {
    path: '/404',
    name: '/404',
    component: () => import('@/views/404.vue')
  },
  ...routers,
]
const  scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition && to.meta.keepAlive) {
    return savedPosition;
  }
  return { x: 0, y:0 };
}

const router = createRouter({
  history: createWebHashHistory('/'),
  routes,
  scrollBehavior,
})
router.beforeEach((to, from, next) => {


  if (to.meta.content) {
    const head = document.getElementsByTagName('head')[0];
    // 删除现有名称为 "keywords" 和 "description" 的 meta 标签
    const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
    if (existingKeywordsMeta) {
        existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
    }
    const existingDescriptionMeta = document.querySelector('meta[name="description"]');
    if (existingDescriptionMeta) {
        existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
    }

    // 创建并设置新的关键词和描述 meta 标签
    const keywordsMeta = document.createElement('meta');
    keywordsMeta.setAttribute('name', 'keywords');
    keywordsMeta.setAttribute('content', to.meta.content.keywords);
    head.appendChild(keywordsMeta); // 添加新的关键词 meta 标签

    const descriptionMeta = document.createElement('meta');
    descriptionMeta.setAttribute('name', 'description');
    descriptionMeta.setAttribute('content', to.meta.content.description);
    head.appendChild(descriptionMeta); // 添加新的描述 meta 标签

    // 可选：如果需要，更新名为 "content" 的其他 meta 标签
    const contentMeta = document.querySelector('meta[name="content"]');
    if (contentMeta) {
        contentMeta.setAttribute('content', to.meta.content); // 更新 content 属性
    }
  }
  

  next();
});
export default router
