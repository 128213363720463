<template>
  <div id="app" class="app" ref="boxRef">
    <Header></Header>
    <main ref="main">
      <el-config-provider :locale="zhCn">
          <!-- <router-view  :key="router.fullPath" /> -->
        <router-view v-slot="{ Component }">
          <keep-alive >
            <component :is="Component" :key="router.fullPath"></component>
          </keep-alive>
        </router-view>
      </el-config-provider>
    </main>
    <Footer></Footer>
  </div>
</template>

<script setup>
import { inject, ref, onMounted, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Header from "./components/header/header.vue";

import Footer from "./components/footer/footer.vue";

import zhCn from "element-plus/dist/locale/zh-cn.mjs";

const store = useStore();
const router = useRoute();
store.dispatch("nav/getNavList");
// store.dispatch('nav/getPeopleList');
</script>

<style lang="scss">
#app,
html,
body,
.app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  font-family: FangSong_GB2312;
}
#app {
  // padding-top: 50px;
  font-family: "思源黑体 Normal", Helvetica, "Microsoft YaHei", Arial,
    sans-serif !important;
  font-size: 100%;
  color: #333333;
  height: 100%;
  // 火狐浏览器滚动条自定义
  scrollbar-width: none;
  /* thin 很细 */
  /* auto 默认 */
  /* none 将滚动条隐藏 */
}
.app {
  main {
    width: 100%;
    min-height: 80vh;
    // margin-top:75px;
  }
}
</style>