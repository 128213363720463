<template>
    <footer>
        {{banquan}}
    </footer>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRouter } from "vue-router"
import { ElMessage } from "element-plus";

import { inject, reactive,ref } from "vue";
import {
  getOneWeixinDetail,
} from "@/common/api.js";
const {state} = useStore();
const router = useRouter();
let banquan=ref('');
banquan.value="Copyright @ 2016 清华大学 能源与动力工程系 燃烧能源中心 版权所有 京ICP备16029131号-1"
</script>
<style lang="scss" scoped>
footer {
    width: 100%;
    height: 5.125rem;
    background: #7030a0;
    margin-top: 3.75rem;
    font-family: FangSong_GB2312;
    font-weight: 400;
    font-size: 1.3125rem;
    color: #FFFFFF;
    text-align: center;
    line-height: 5.125rem;
}

</style>
<style scoped>
@media screen and (max-width: 420px) {
    footer{
        width: 100%;
        height: auto;
        font-size: 12px;
        margin-top: 0;
        /* line-height: 50px; */
        padding: 10px 0;
    }
}
</style>