function phoneInput(e) {
    console.log(e)
}
export default {
    errorToBack: errorToBack,
    getStorage: getStorage,
    removeStorage: removeStorage,
    phoneInput: phoneInput,
    isWeiXinBrowser:isWeiXinBrowser,
    jumpToLoginWeiXin:jumpToLoginWeiXin,
    titleID: [{ name: "先生", titleID: '1d88af067fff4d6e95575e77f7365029' }, { name: "女士", titleID: '384aba23a5ac4344b5848250d793cd64' }],
    
    // applicationID: '8a2f462a9048112801904875087e001e',
    applicationID: '4028fcb9907755660190809f8618000a',
    //官网站点ID
    // siteID: '8a2f462a903f8c7d019047c9e65403b3',
    siteID: '4028fcb99080c4fc019081280a85001a',

    //公司ID
    // companyID: 'cc1a065429824e6b8e650648cde995ec',
    companyID: '4028fcb9907755660190809eae9a0000',
    //导航ID
    navIdList: {
        // footerId: '2aebb8e2ff8041b09dc7becfb275e5d9',
        // headerId: 'bbd87eaefbdf46048e4ca7fb2666e4d4',
        footerId: '5f288f38c25449f38b81073f077d084b',
        headerId: 'b285b52bf5a9492badea63a021be99b0',
    },

    //轮播图ID
    swiper:{
        homeId:'cc5145a108ad4defbdcd936d62294c8d'
    },
    // homeNavID:"b1ce394a43d2421ea6089b3d318a3a5c",//首页
    // newsNavID:"e99ebf1181644367a9220093fab04c82",//新闻
    // peoNavID:"ecf8e9ebfb8347a287035666e0ac359e",//人员
    // resNavID:"24615f8205804acd82635bc30f8fbee1",//研究
    // pubNavID:"643d810229bc43d1992f863d7472330a",//成果
    // labNavID:"ed5ba27a2b814a3b898e163a6bc521e0",//实验室
    // teaNavID:"6905a71828974c469878876e0d939339",//教师
    // recNavID:"ef1f3d1bee8d40ddbd24e00d6c54baf7",//招聘
    homeNavID:"04c33a98d0be4355bcf99c9ce2635f9a",//首页
    newsNavID:"1780d5734dda461a869d054c09da7787",//新闻
    peoNavID:"cce222a5276047408adc889935e0f537",//人员
    resNavID:"b529a845fd1544548dfdfa3d2282bfe7",//研究
    pubNavID:"8aea79d54ac943748c1600f663350fa2",//成果
    labNavID:"177aa3afd7ac4cad91f6f4a952f8de01",//实验室
    teaNavID:"109d03fcbe304df793802644f6fa3e51",//教师
    recNavID:"f917e4d5ad464c5c92816aa69955936e",//招聘
    //语言
    language: true,
    // deviceID: '0268fffba23f48bf877f445e0536e45a',//好聚
    // deviceID: '8a2f462a8a5f12bd018a683737c20937',//鲁南
    
    periodType:[{id:'1',name:'日',shortName:'day'},{id:'2',name:'月',shortName:'month'},{id:'3',name:'年',shortName:'year'},{id:'10',name:'次',shortName:'times'}]
}
// 获取localStorage
function getStorage(name) {
    if (!name) return
    return window.localStorage.getItem(name)
}

// 删除localStorage
function removeStorage(name) {
    if (!name) return
    window.localStorage.removeItem(name)
}
// 当出错的时候，显示错误信息，并且跳转
function errorToBack(msg = '出错了，请重试', time = 1500) {
    console.log(msg)
    // _this.$dialog.toast({
    //     mes: msg,
    //     timeout: time
    // })
}
function isWeiXinBrowser() {
    // window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase()
    // 通过正则表达式匹配ua中是否含有MicroMessenger字符串
    var uas = ua.match(/MicroMessenger/i)
    if (uas) {
        if (ua.match(/MicroMessenger/i)[0] === 'micromessenger') {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}
function jumpToLoginWeiXin() {
    let that = _this;
    removeStorage('sessionID');
    removeStorage('memberID');
    that.code = ''
    var local = location.origin + '/authNew';
    var appid = 'wxa41d50db32b07abc';
    that.code = getUrlCode().code // 截取code
    if (that.code == null || that.code === '') { // 如果没有code，则去请求
        setStorage('beforeUrl', route.fullPath)
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
    } else {
        // 有code
    }
}