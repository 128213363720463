import { getNavigatorList, getFocusList, getNavigatorSimpleArticleList ,getArticlePublishList,getAllMemberRecommendList} from "../../common/api"
import { ElMessage } from "element-plus";
import Common from "../../common/common";
const arrayChunk=(array, size)=> {
    let data = []
    for (let i = 0; i < array.length; i += size) {
        data.push(array.slice(i, i + size))
    }
    return data
}
export default {
    namespaced: true,
    state() {
        return {
            // 导航信息
            navList: [],
            headerList: [],//导航栏导航列表
            footerList: [],
            peopleList: [],
        }
    },
    mutations: {
        navId(state, data) {
            state.navList = data
        },
        footerId(state, data) {
            state.footerList = { ...data }
        },
        headerId(state, data) {
            state.headerList = { ...data }
        },
        setPeopleList(state, data) {
            state.peopleList = data
        },
    },
    //这里定义异步操作和提交mutations的方法
    actions: {
        //获取导航列表
        async getNavList(context) {
            for (let key in Common.navIdList) {
                getNavigatorList({
                    siteID: Common.siteID,
                    navigatorID: Common.navIdList[key],
                    sortTypeOrder: 1
                }, res => {
                    let data = []
                    if (res.data.header.code === 0) {
                        data = res.data.body.data.rows
                        if (key !== 'footerId') {
                            if(key =='RecommendedNavigationId'){
                                data.forEach(item => {
                                    item.typeSSR=item.tagCode.split(',')[0]
                                });
                                context.commit(key, data)
                                return
                            }
                            if(key =='topHeaderID'){
                                let li=arrayChunk(data,Math.ceil(data.length/2))
                                context.commit('settopHeaderListLeft', li[0])
                                context.commit('settopHeaderListRight', li[1])
                                context.commit(key, data)
                                return
                            }
                            context.commit(key, data)
                            // console.log('111',data);
                            return
                        }
                        
                        data.forEach(item => {
                            
                            getNavigatorList({
                                siteID: Common.siteID,
                                navigatorID: item.navigatorID,
                                sortTypeOrder: 1,
                                pageNumber:9999,
                            }, res => {
                                item.rows = res.data.body.data.rows
                                item.rows.forEach((it)=>{
                                    if(it.name.length>20){
                                        it.name=Common.getNewline(it.name,20)
                                        it.shortName=Common.getNewline(it.shortName,20)
                                        // console.log(Common.getNewline(it.name,20));
                                    }
                                })
                                context.commit(key, data)
                                // console.log('222333',data);
                            })
                        });
                    } else {
                        ElMessage({
                            message: '请求失败',
                            type: 'error',
                        })
                    }
                })
            }
        },
        //获取swiper
        getSwiper(context) {
            for (let key in Common.swiper) {
                getFocusList({
                    siteID: Common.siteID,
                    focusID: Common.swiper[key],
                    sortTypeOrder: 1
                }, res => {
                    if (res.data.header.code === 0) {
                        context.commit(key, res.data.body.data.rows)
                    } else {
                        ElMessage({
                            message: '请求失败',
                            type: 'error',
                        })
                    }
                })
            }
        },
        //获取newSwiper
        getNewSwiper(context) {
            for (let key in Common.newSwiper) {
                getFocusList({
                    siteID: Common.siteID,
                    focusID: Common.newSwiper[key],
                    sortTypeOrder: 1
                }, res => {
                    if (res.data.header.code === 0) {
                        context.commit(key, res.data.body.data.rows)
                    } else {
                        ElMessage({
                            message: '请求失败',
                            type: 'error',
                        })
                    }
                })
            }
        },
        //获取导航列表
        getNavList2(context,shortName) {
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: Common.navIdList.headerId,
                sortTypeOrder: 1
            }, res => {
                let data = []
                if (res.data.header.code === 0) {
                    data = res.data.body.data.rows
                    data.forEach((it)=>{
                        if(it.name=='我的账户'){
                            it.name=`${it.name}${shortName}`
                            it.shortName=`${it.shortName}${shortName}`
                        }
                    })
                    // console.log('data',data);
                    context.commit('headerId', data)
                } else {
                    ElMessage({
                        message: '请求失败',
                        type: 'error',
                    })
                }
            })
        },
        //获取导航列表
        getNavList3(context,) {
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: Common.navIdList.footerId,
                sortTypeOrder: 1
            }, res => {
                let data = []
                if (res.data.header.code === 0) {
                    data = res.data.body.data.rows
                    data.forEach((it)=>{
                        if(it.name.length>10){
                            Common.getNewline(item.name,16)
                            // console.log(Common.getNewline(item.name,16));
                        }
                    })
                    // console.log('data222',data);
                    context.commit('footerId', data)
                } else {
                    ElMessage({
                        message: '请求失败',
                        type: 'error',
                    })
                }
            })
        },
        // 获取图书馆导航列表并赋值给headerList
        getLibarayNavList(context) {
            getNavigatorList({
                siteID: Common.libaraySiteID,
                navigatorID: Common.libarayNavID,
                sortTypeOrder: 1
            }, res => {
                if(res.data.header.code==0){
                    context.commit('headerId', res.data.body.data.rows)
                    context.commit('setNavCode',1)
                }else{
                    ElMessage({
                        message: '请求失败',
                        type: 'error',
                    })
                }
            })
        },
        // 获取官网导航列表并赋值给headerList
        getHeaderNavList(context) {
            getNavigatorList({
                siteID: Common.siteID,
                navigatorID: Common.navIdList.headerId,
                sortTypeOrder: 1
            }, res => {
                if(res.data.header.code==0){
                    context.commit('headerId', res.data.body.data.rows)
                    context.commit('setNavCode',0)
                }else{
                    ElMessage({
                        message: '请求失败',
                        type: 'error',
                    })
                }
            })
        },
        // 获取人员列表
        getPeopleList(context){
            console.log("获取人员列表");
            getNavigatorList({
              siteID: Common.siteID,
              navigatorID: Common.peoNavID,
              sortTypeOrder: 1,
              isValid:1,
              isLock:0,
            },(res)=>{
              if(res.data.header.code==0){
                let navList=res.data.body.data.rows;
                let num=0
                let total=res.data.body.data.total
                console.log("导航调用完成",navList);
                navList.forEach((item,index)=>{
                  console.log("开始导航下面每个导航的会员推荐");
                  getAllMemberRecommendList({
                    navigatorID:item.navigatorID,
                    sortTypeTime:2,
                    isValid:1,
                    isLock:0,
                    pageNumber:99,
                  },res1=>{
                    if(res1.data.header.code==0){
                      let obj=res1.data.body.data.rows
                      item.memberPublishList=obj
                      console.log("调用会员推荐完成");
                    }
                  })
                  num++
                  if(num==total){
                    //   this.navList=navList
                    context.commit("setPeopleList",navList)
                    console.log("导航",navList);
                    }
                })
                
              }
            })
          },
    }
}