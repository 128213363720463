import "./assets/icon/SourceHanSansCN-VF-2.css"
import { createApp } from 'vue'
import store from './store'
import router from './router'
import './assets/style/style.css'
import App from './App.vue'
import Common from './common/common'
import Jump from '@/components/js/index.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import animated from 'animate.css'
import login from './components/login/index'
// 引入字体
import './assets/font.css'
import 'lib-flexible'
// 引入icon
import './assets/iconFont/iconfont.css'; // 引入自定义图标样式文件
import './assets/arrowIcon/iconfont.css'; // 引入自定义图标样式文件
import MyIcon from '@/components/MyIcon.vue'; // 你自己的vue文件路径
// 视频播放组件
import VideoPlayer from 'vue-video-player/src';
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
// 腾讯云 云点播视频播放组件
import TCPlayer from 'tcplayer.js';
import 'tcplayer.js/dist/tcplayer.min.css';
// 引入base64加密
import Base64SSR from '@/utils/base64.js' 
// 引入所有图标  
import * as ElIcons from '@element-plus/icons-vue'


let Vue = createApp(App)


Vue.provide('Base64', Base64SSR)
Vue.provide('Common', Common)
Vue.provide('Jump', Jump)
Vue.provide('login', login)
Vue.component('MyIcon', MyIcon); // 使用
// 注册所有图标  
for (const [key, component] of Object.entries(ElIcons)) {  
    Vue.component(key, component)  
  } 
Vue.use(store).use(router).use(animated).use(ElementPlus).use(VideoPlayer).mount('#app')

