<template>
  <header class="mainBoy">
    <div class="blockBox"></div>
    <div class="lockHeader">
      <div class="leftLockHeadeR">
        <img class="logoImg" src="https://abd.tsinghua-suigroup.com/4d827c6fd9d6a554b14eddfa5c0255b.png" alt="" />
      </div>
      <div class="rightLockHeader">
        <div class="text1">异相反应流与能源转化课题组</div>
        <div class="text2">
          Group for Heterogeneous Reacting Flows and Energy Conversion
        </div>
        <div class="text3">清华大学 能源与动力工程系 燃烧能源中心</div>
        <div class="text4">
          Center for Combustion Energy, Dept. Energy and Thermal Engineering,
          Tsinghua University
        </div>
      </div>
      <div class="menu" @click="openNavList"></div>
    </div>
    <div class="navigator">
      <router-link
        class="navItem"
        v-for="item in store.state.nav.headerList"
        :key="item.navigatorID"
        :to="item.functionURL ? item.functionURL : ''"
        active-class="active"
      >
        <div class="navItemTop">
          {{ item.name }}
        </div>
        <!-- <div class="navItemLink"></div> -->
        <div class="navItemBot">
          {{ item.shortName }}
        </div>
      </router-link>
    </div>
    <div class="shuNavList">
      <el-drawer
        v-model="drawer"
        title=""
        :with-header="false"
        :direction="direction"
        :before-close="handleClose"
        size="50%"
      >
        
        <div class="shuNavContent">
          <div class="shuNavTop">
            清华大学隋然课题组
          <div>Sui Research Group<br/>
            Tsinghua University</div>
          </div>
          <router-link
            class="shuNavItem"
            v-for="item in store.state.nav.headerList"
            :key="item.navigatorID"
            :to="item.functionURL ? item.functionURL : ''"
            active-class="active1"
            @click="drawer = false"
          >
            <div class="navItemTop">
              {{ item.name }}
              {{ item.shortName }}
            </div>
            <!-- <div class="navItemLink"></div> -->
            <div class="navItemBot">
              <!-- {{ item.shortName }} -->
              <el-icon size="16" color="#fff"><ArrowRightBold /></el-icon>
            </div>
          </router-link>
        </div>
      </el-drawer>
    </div>
  </header>
</template>
<script setup>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Common from "@/common/common.js";
import Jump from "@/components/js/index.js";
import { getCartGoodsTotal } from "@/common/api.js";
import { inject, ref, onMounted, reactive, watch } from "vue";
import { ArrowRightBold } from '@element-plus/icons-vue'
const store = useStore();
const router = useRouter();

const data = reactive({
  language: Common.language,
});

watch(router, (newVal, oldVal) => {});

import { ElMessageBox } from "element-plus";
import { DrawerProps } from "element-plus";

const drawer = ref(false);

const direction = ref("rtl");

const handleClose = () => {
  drawer.value = false;
};
function openNavList() {
  drawer.value = true;
}
</script>
<style lang="scss" scoped>
.mainBoy {
  width: 100%;
  // padding: 0 12.5rem;
  // padding: 0 8.5rem;
}
.blockBox {
  width: 100%;
  height: 0.3125rem;
}
.lockHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #7030a0;
  padding:2.5rem 10.5rem;
  .leftLockHeadeR {
    width: 9.375rem;
    height: 9.375rem;
    .logoImg {
      width: 100%;
      height: 100%;
    }
  }
  .rightLockHeader {
    flex: 1;
    height: 9.375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 1.25rem;
    .text1 {
      // font-size: 1.5rem;
      font-size: 1.5625rem;
      color: #fff;
      font-weight: bold;
      letter-spacing: 0.1875rem;
      font-family: FangSong_GB2312;
    }
    .text2 {
      // font-size: 1.375rem;
      font-size: 1.4375rem;
      color: #fff;
      font-weight: bold;
      word-spacing: 0.3125rem;
      font-family: Calibri;
    }
    .text3 {
      // font-size: 1.25rem;
      font-size: 1.3125rem;
      color: #fff;
      font-weight: bold;
      letter-spacing: 0.1875rem;
      font-family: FangSong_GB2312;
    }
    .text4 {
      // font-size: 1.25rem;
      font-size: 1.3125rem;
      color: #fff;
      font-weight: bold;
      word-spacing: 0.3125rem;
      font-family: Calibri;
    }
  }
}
.navigator {
  // width: 100%;
  margin: 2.5rem  8.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.5rem;
  // border: 1px solid #333;
  .navItem {
    width: 12.5%;
    height: 4.15rem;
    // font-size: 1.26rem;
    font-size: 1.3225rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-right: 1px solid #333;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    font-family: FangSong_GB2312;
    .navItemLink {
      width: 2.5rem;
      height: 0.25rem;
      background-color: #7030a0;
    }
    .navItemTop {
      font-family: FangSong_GB2312;
    }
    .navItemBot {
      font-family: Calibri;
    }
  }
  .navItem:first-child {
    border-left: 1px solid #333;
  }
}
.active {
  background-color: #fff2cc;
  color: #7030a0;
  font-weight: bold;
}
.shuNavList {
}
</style>
<style scoped lang="scss">
@media screen and (max-width: 420px) {
  .mainBoy {
    width: 100%;
    padding: 10px 20px;
    background: #FFFFFF;
    box-shadow: 11px 0px 11px 0px #c7c7c7;
    .blockBox {
      display: none;
    }
    .lockHeader {
      width: 100%;
      display: flex;
      

      .leftLockHeadeR {
        width: 50px;
        height: 50px;
      }
      .rightLockHeader {
        flex: 1;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .text1 {
          font-size: 14px;
          // color: #fff;
        }
        .text2 {
          font-size: 10px;
          // color: #fff;
        }
        .text3,
        .text4 {
          display: none;
        }
      }
      .menu {
        background: url("./navA.png") center no-repeat;
        background-size: 30px;
        width: 30px;
        height: 30px;
      }
    }
    .navigator {
      display: none;
    }
    .shuNavList{
      
      .shuNavContent{
        background-color: #34a78a;
        min-height: 100vh;
        .shuNavTop{
          width: 100%;
          // height: 10vh;
          color: #fff;
          font-size: 16px;
          font-weight: bold;
          padding: 5px 10px;
          text-align: center;
          // line-height: 8vh;
        }
        .shuNavItem{
          padding: 5px 10px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #42b294;
          .navItemTop{
            color: #fff;
            font-size: 16px;
          }
          .navItemBot{
            color: #fff;
            margin-left: 5px;
            font-size: 16px;
          }
        }
        .active1{
          color: #7030a0;
        }
      }
    }
  }
  
}
::v-deep .el-drawer__body{
        padding: 0;
      }
</style>